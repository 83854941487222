/*.CUSTOM_BUTTON_WIDGET{*/

/*}*/
/*.CUSTOM_BUTTON_WIDGET .bp3-button{*/
/*  direction: rtl;*/
/*  font-family: NovemberHebrew-Regular;*/
/*  font-weight: 400;*/
/*  border: 1px solid #ddd !important;*/
/*  border-radius: 1.5px !important;*/
/*  text-align: right;*/
/*}*/
/*.CUSTOM_BUTTON_WIDGET .bp3-button:hover {*/
/*   background-color: #3071a9 ;*/
/* }*/
/*.CUSTOM_BUTTON_WIDGET .bp3-button span{*/
/*   text-align: inherit;*/
/* }*/
/*.CUSTOM_CHART_WIDGET {*/

/*}*/
/*.CUSTOM_CHECKBOX_WIDGET {*/

/*}*/
/*.CUSTOM_CHECKBOX_WIDGET label input:checked ~ .bp3-control-indicator{*/
/*  box-shadow: none;*/
/*  border: 2px solid #007ad9 !important;*/
/*  background: #007ad9 !important;*/
/*  color: #fff;*/
/*  margin-left: 8px;*/
/*  margin-right: 0;*/
/*}*/
/*.CUSTOM_CHECKBOX_WIDGET label .bp3-control-indicator{*/
/*  margin-left: 8px !important;*/
/*  margin-right: 0;*/
/*}*/
/*.CUSTOM_CHECKBOX_WIDGET .bp3-checkbox {*/
/*  direction: rtl;*/
/*  padding-left: 0 !important;*/
/*  white-space: nowrap;*/
/*  text-align: right;*/
/*  width: 100%;*/
/*  color: #000;*/
/*}*/
/*.CUSTOM_CHECKBOX_WIDGET input{*/
/*  right: 0;*/
/*}*/
/*.CUSTOM_CONTAINER_WIDGET{*/
/*  !*background-color: #fcfcfc*!*/
/*}*/
/*.CUSTOM_DATE_PICKER_WIDGET{*/

/*}*/
/*.CUSTOM_DROP_DOWN_WIDGET{*/
/*  direction: rtl;*/
/*}*/
/*.CUSTOM_DROP_DOWN_WIDGET .bp3-multi-select{*/
/*  direction: rtl;*/
/*  text-align: right;*/
/*}*/
/*.CUSTOM_DROP_DOWN_WIDGET .bp3-button {*/
/*  border-radius: 3px;*/
/*}*/
/*.CUSTOM_DROP_DOWN_WIDGET .bp3-button span{*/
/*  direction: rtl;*/
/*  text-align: right;*/
/*}*/
/*.bp3-multi-select-popover .bp3-popover-content{*/
/*  direction: rtl;*/
/*  text-align: right;*/
/*}*/
/*.bp3-multi-select-popover .bp3-popover-content .bp3-control-indicator{*/
/*  margin: 0 0 0 5px !important;*/
/*}*/
/*.bp3-multi-select-popover .bp3-popover-content div{*/
/*  direction: rtl;*/
/*  text-align: right;*/
/*}*/
/*.bp3-select-popover .bp3-popover-content{*/
/*  direction: rtl;*/
/*  text-align: right;*/
/*}*/
/*.bp3-select-popover .bp3-popover-content .bp3-control-indicator{*/
/*  margin: 0 0 0 5px !important;*/
/*}*/
/*.bp3-select-popover .bp3-popover-content div{*/
/*  direction: rtl;*/
/*  text-align: right;*/
/*}*/
/*.CUSTOM_FILE_MANAGER_WIDGET{*/

/*}*/
/*.CUSTOM_FILE_PICKERS3_WIDGET{*/

/*}*/
/*.CUSTOM_FILE_PICKER_WIDGET{*/

/*}*/
/*.CUSTOM_FORM_BUTTON_WIDGET{*/

/*}*/
/*.CUSTOM_FORM_BUTTON_WIDGET .bp3-button{*/
/*   direction: rtl;*/
/*   font-family: NovemberHebrew-Regular;*/
/*   font-weight: 400;*/
/*   border: 1px solid #ddd !important;*/
/*   border-radius: 1.5px !important;*/
/*   text-align: right;*/
/*}*/
/*.CUSTOM_ICON_WIDGET {*/

/*}*/
/*.CUSTOM_IMAGE_WIDGET {*/

/*}*/
/*.CUSTOM_INPUT_WIDGET {*/

/*}*/
/*.CUSTOM_INPUT_WIDGET input{*/
/*  direction: rtl;*/
/*  font-family: NovemberHebrew-Regular;*/
/*  font-weight: 400;*/
/*  border: 1px solid #ddd !important;*/
/*  border-radius: 1.5px !important;*/
/*  !*padding: 0.5px 25px 0.5px 0.5px;*!*/
/*  color: #000;*/
/*}*/
/*.CUSTOM_INPUT_WIDGET input::placeholder{*/
/*   color: #666666 !important;*/
/* }*/
/*.CUSTOM_KANBAN_WIDGET{*/

/*}*/
/*.CUSTOM_LABEL_WIDGET{*/

/*}*/
/*.CUSTOM_LABEL_WIDGET .bp3-ui-text{*/
/*  font-size: 12px;*/
/*  font-weight: 400 !important;*/
/*  font-family: NovemberHebrew-Regular;*/
/*  text-align: right;*/
/*  color: #000;*/
/*}*/
/*.CUSTOM_LABEL_WIDGET .bp3-ui-text span{*/
/*  text-align: right;*/
/*  color: #000;*/
/*  direction: rtl;*/
/*}*/
/*.CUSTOM_LABEL_WIDGET .bp3-heading{*/
/*  font-size: 20px;*/
/*  font-weight: 700;*/
/*  font-family: NovemberHebrew-Bold;*/
/*  color: #000;*/
/*  direction:rtl;*/
/*}*/
/*.CUSTOM_MAP_WIDGET{*/

/*}*/
/*.CUSTOM_MODAL_WIDGET{*/

/*}*/
/*.CUSTOM_RADIO_GROUP_WIDGET{*/

/*}*/
/*.CUSTOM_RICH_TEXT_EDITOR_WIDGET{*/

/*}*/
/*.CUSTOM_SLIDER_WIDGET{*/

/*}*/
/*.CUSTOM_TABLE_WIDGET{*/

/*}*/
/*.CUSTOM_TABLE_WIDGET .header-reorder{*/
/*   direction: rtl;*/
/*   font-family: NovemberHebrew-Regular;*/
/*   font-weight: 400;*/
/*}*/
/*.CUSTOM_TABLE_WIDGET .bp3-button{*/
/*  direction: rtl;*/
/*  font-family: NovemberHebrew-Regular;*/
/*  font-weight: 400;*/
/*  border: 1px solid #ddd !important;*/
/*  border-radius: 1.5px !important;*/
/*  text-align: right;*/
/*}*/
/*.CUSTOM_TABLE_WIDGET .bp3-button:hover {*/
/*  background-color: #3071a9 ;*/
/*}*/
/*.CUSTOM_TABLE_WIDGET .bp3-button span{*/
/*  text-align: inherit;*/
/*}*/
/*.CUSTOM_TABLE_WIDGET .td{*/
/*  border: none !important;*/
/*  height: auto !important;*/
/*  font-size: 14px !important;*/
/*  line-height: 20px !important;*/
/*  padding: 12px 0px 12px 0px !important;*/
/*}*/
/*.CUSTOM_TABLE_WIDGET .td div{*/
/*  white-space: unset !important;*/
/*  direction: rtl;*/
/*  font-family: NovemberHebrew-Regular;*/
/*  font-weight: 400;*/
/*  border: none;*/
/*  color: #000;*/
/*}*/
/*.CUSTOM_TABLE_WIDGET .td span{*/
/*  white-space: unset !important;*/
/*  direction: rtl;*/
/*  font-family: NovemberHebrew-Regular;*/
/*  font-weight: 400;*/
/*  border: none;*/
/*}*/
/*.CUSTOM_TABLE_WIDGET .tr:nth-child(odd) {*/
/*  background: #fff !important;*/
/*}*/
/*.CUSTOM_TABLE_WIDGET .tr:nth-child(even) {*/
/*  background: #f9f9f9 !important;*/
/*}*/
/*.CUSTOM_TABS_WIDGET{*/

/*}*/
/*.CUSTOM_TABS_WIDGET > div  > div  {*/
/*  background-color: #fcfcfc*/
/*}*/
/*.CUSTOM_TABS_WIDGET > div  > div > div {*/
/*  background-color: #fcfcfc*/
/*}*/
/*div[type=FORM_WIDGET]{*/
/*  background-color: #fcfcfc*/
/*}*/
/*div[type=TABS_WIDGET]{*/
/*  background-color: #fcfcfc*/
/*}*/
/*div[type=CONTAINER_WIDGET]{*/
/*  background-color: #fcfcfc*/
/*}*/
/*.CUSTOM_TEXT_WIDGET{*/

/*}*/
/*.CUSTOM_TEXT_WIDGET .bp3-ui-text{*/
/*  font-size: 12px;*/
/*  font-weight: 400 !important;*/
/*  font-family: NovemberHebrew-Regular;*/
/*  text-align: right;*/
/*  color: #000;*/
/*}*/
/*.CUSTOM_TEXT_WIDGET .bp3-ui-text span{*/
/*  text-align: right;*/
/*  color: #000;*/
/*  direction: rtl;*/
/*}*/
/*.CUSTOM_TEXT_WIDGET .bp3-heading{*/
/*  font-size: 20px;*/
/*  font-weight: 700;*/
/*  font-family: NovemberHebrew-Bold;*/
/*  color: #000;*/
/*  direction:rtl;*/
/*}*/
/*.CUSTOM_VIDEO_WIDGET{*/

/*}*/
/*.CUSTOM_MAIN_CONTAINER{*/
/*  background-color: #e4e7ea;*/
/*}*/
